import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "../../GeneralComponents/Container";
import { Topbar, Sidebar } from "../components";
import setGlobalLoader from "../../../redux/actions/loaderActions";
import { createVideo, getVideoCategories } from "../../../redux/actions/apiActions";
import { CircularProgress } from "@mui/material";
import { PROFILES } from "../../../utils/constants";
import Select from 'react-select';

const validationSchema = yup.object({
    title: yup.string().trim().required("Name is required."),
    videoCategoryId: yup.string().trim().required("video is required."),
    videoId: yup.string().trim().required("video Id is required."),
});

const AddVideos = () => {
    const [selectedCategory, setSelectedCategory] = useState({});
    const [category, setCategory] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const profile = useSelector(state => state.authReducer.profile);
    const role = useSelector(state => state.authReducer.role);
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };
    const open = isMd ? false : openSidebar;


    /**
     * Extracts the YouTube video ID from a given URL.
     * Supports shared URLs (e.g., youtu.be) and embedded URLs within iframe tags.
     *
     * @param {string} url - The YouTube URL to extract the video ID from.
     * @returns {string|null} The extracted YouTube video ID, or null if the URL is invalid.
     */
    function extractYoutubeVideoId(url) {
        if (typeof url !== "string") {
            throw new Error('Input URL must be a string.');
        }

        // Regular expression pattern to match YouTube video ID
        const youtubeVideoIdPattern = /(?:youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/watch\?v=)([^&\s]+)/i;

        // Helper function to extract src from iframe tags
        function extractSrcFromIframe(iframeContent) {
            const iframeSrcPattern = /<iframe[^>]*src="([^"]+)"/i;
            const match = iframeContent.match(iframeSrcPattern);
            return match && match[1] ? match[1] : null;
        }

        let processedUrl = url;

        // Check if the input is an iframe tag content and extract the src URL if so
        if (processedUrl.includes('<iframe') && processedUrl.includes('</iframe>')) {
            const iframeSrc = extractSrcFromIframe(processedUrl);
            if (iframeSrc) {
                processedUrl = iframeSrc;
            } else {
                // If iframe but no src found, consider it as invalid for our purpose
                return null;
            }
        }

        // Execute the regular expression to find the video ID
        const match = youtubeVideoIdPattern.exec(processedUrl);

        // Return the video ID if found, otherwise null
        return match && match[1] ? match[1] : null;
    }

    const handleOnSubmit = async (values) => {
        console.log("form values: ", values)
        try {
            await createVideo(role, values);
            toast.success("Videos added successfully");
            navigate("/videos");
            dispatch(setGlobalLoader(false));
        } catch (error) {
            dispatch(setGlobalLoader(false));
            console.log("create Videos error", error);
            toast.error(
                error.response.data.msg ??
                "some error occured while creating Videos"
            );
        }
    };

    useEffect(() => {
        if (profile === PROFILES.ADMIN) {
            dispatch(setGlobalLoader(true))
            async function videosFunc() {
                try {
                    const videoCatRes = await getVideoCategories(role);
                    const data = videoCatRes.data.data.map((d, i) => ({
                        label: d?.title,
                        value: d?._id,
                        //  registeredOn: format(new Date(d?.createdAt), 'MMM dd, yyyy'),
                    }))
                    setCategory(data);
                    dispatch(setGlobalLoader(false))
                } catch (error) {
                    dispatch(setGlobalLoader(false))
                    console.log("get Organizations error", error);
                    toast.error(error.response.data.msg ?? "some error occured while fetching organization list");
                }
            }
            videosFunc();
        }
    }, [])

    return (
        <Box>
            <AppBar
                position={"fixed"}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                }}
                elevation={0}
            >
                <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
                    <Topbar onSidebarOpen={handleSidebarOpen} />
                </Container>
            </AppBar>
            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant={isMd ? "permanent" : "temporary"}
            />
            <main>
                <Box height={{ xs: 58, sm: 66, md: 71 }} />
                <br />
                <br />

                <Box overflow="hidden" marginLeft={{ md: "270px" }}>
                    <Typography
                        variant={"subtitle2"}
                        sx={{ marginBottom: 2 }}
                        fontWeight={700}
                    >
                        Add Video
                    </Typography>

                    <Formik
                        initialValues={{
                            title: "",
                            videoCategoryId: "",
                            videoId: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleOnSubmit(values)}
                        enableReinitialize
                    >
                        {(formikProps) => {
                            const {
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting,
                            } = formikProps;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid
                                        container
                                        spacing={{ xs: 2, md: 4 }}
                                        sx={{ padding: "10px" }}
                                    >
                                        <Grid item xs={12} sm={6} md={7}>
                                            <Typography
                                                variant={"subtitle2"}
                                                sx={{ marginBottom: 2 }}
                                                fontWeight={700}
                                            >
                                                Video Title
                                            </Typography>
                                            <TextField
                                                label="Title *"
                                                variant="outlined"
                                                name={"title"}
                                                fullWidth
                                                value={values.title}
                                                onChange={(e) => setFieldValue("title", e.target.value)}
                                                error={touched.title && Boolean(errors.title)}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={7}>
                                            <Typography
                                                variant={"subtitle2"}
                                                sx={{ marginBottom: 2 }}
                                                fontWeight={700}
                                            >
                                                Video Category
                                            </Typography>
                                            <Select
                                                styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                                }}
                                                label="Video Id *"
                                                placeholder="Select Car Category"
                                                value={selectedCategory}
                                                options={category}
                                                color="primary"
                                                fullWidth
                                                onChange={(e) => {
                                                    setSelectedCategory(e)
                                                    setFieldValue("videoCategoryId", e.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={7}>
                                            <Typography
                                                variant={"subtitle2"}
                                                sx={{ marginBottom: 2 }}
                                                fontWeight={700}
                                            >
                                                Video Url
                                            </Typography>
                                            <TextField
                                                label="Video Id *"
                                                variant="outlined"
                                                name={"videoId"}
                                                fullWidth
                                                type="text"
                                                value={values.videoId}
                                                onChange={(e) => setFieldValue("videoId", extractYoutubeVideoId(`${e.target.value}`))
                                                }
                                                error={
                                                    touched.videoId && Boolean(errors.videoId)
                                                }
                                                helperText={
                                                    touched.videoId && errors.videoId
                                                }
                                            />
                                        </Grid>

                                        <br />
                                        <br />
                                    </Grid>
                                    <br />
                                    <Button
                                        component={"button"}
                                        variant={"contained"}
                                        fontWeight={700}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress /> : "Submit"}
                                    </Button>
                                </form>
                            );
                        }}
                    </Formik>
                </Box>
            </main>
        </Box>
    );
};

export default AddVideos;
