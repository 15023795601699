/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import setGlobalLoader from '../../redux/actions/loaderActions'
import { organizationSignup } from '../../redux/actions/apiActions'
import { statesAndCities } from '../../utils/constants'
import { CircularProgress, FormControl } from '@mui/material'

const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .trim()
    .required('Phone Number is required.')
    .min(10, 'Phone number short than 10 digits.')
    .max(10, 'Phone number long than 10 digits.'),
  name: yup.string().trim().required('Name is required.'),
  email: yup.string().trim().required('Email is required.'),
  address: yup.string().trim().required('Address is required.'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  pincode: yup.string().trim().required('Pincode is required.'),
  license: yup.string().trim().max(25, 'License number long than 25 digits.').optional(),
})

const Form = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [selectedState, setSelectedState] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    setStateOptions(statesAndCities.map(item => ({ "label": item.state , "value": item.state })));
    if (selectedState) {
      const cities = statesAndCities.find(state => state.state.toLowerCase() === selectedState?.value.toLowerCase()).city;
      cities.sort((a, b) => a.localeCompare(b));
      setCityOptions(cities.map(city => ({  "label": city , "value": city })));
    }
  }, [selectedState, statesAndCities]);

  const handleOnSubmit = async (values) => {
    console.log("values: ", values)
    if (values.state === '') {
      toast.error('Please select a State.')
      return
    }
    if (values.city === '') {
      toast.error('Please select a City.')
      return
    }
    try {
      await organizationSignup(values)
      toast.success('Details saved successfully. We will contact you soon.')
      dispatch(setGlobalLoader(false))
      setShowSuccessMessage(true);
      setTimeout(() => {
        navigate('/login')
      }, 10000);
    } catch (error) {
      dispatch(setGlobalLoader(false))
      console.log('create Organization error', error)
      toast.error(
        error.response.data.msg ??
        'some error occured while raising the request.'
      )
    }
  }
  return (
    <Box>
      <Formik
        initialValues={{
          phoneNumber: '',
          name: '',
          email: '',
          license: '',
          pincode: '',
          address: '',
          city: '',
          state: '',
          whatsappCommunicatioun: false,
        }}
        validationSchema={validationSchema}
        onSubmit={values => handleOnSubmit(values)}
      >
        {formikProps => {
          const {
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          } = formikProps
          return (
            <>
              <Box marginBottom={4}>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                  }}
                  gutterBottom
                  color={'text.secondary'}
                >
                  Signup
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Welcome
                </Typography>
                <Typography color="text.secondary">
                  {/* Login to manage your account. */}
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        Driving School Name
                      </Typography>
                      <TextField
                        label="Name *"
                        variant="outlined"
                        name={'name'}
                        fullWidth
                        value={values.name}
                        onChange={e => setFieldValue('name', e.target.value)}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        Phone Number
                      </Typography>
                      <TextField
                        label="Phone Number *"
                        variant="outlined"
                        name={'phoneNumber'}
                        fullWidth
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={values.phoneNumber}
                        onChange={e =>
                          e.target.value.toString().length > 10
                            ? setFieldValue(
                              'phoneNumber',
                              e.target.value.substring(0, 10)
                            )
                            : setFieldValue('phoneNumber', e.target.value)
                        }
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        Email
                      </Typography>
                      <TextField
                        label="Email"
                        variant="outlined"
                        name={'email'}
                        fullWidth
                        value={values.email}
                        onChange={e => setFieldValue('email', e.target.value)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        License Number
                      </Typography>
                      <TextField
                        label="License Number"
                        variant="outlined"
                        name={'license'}
                        type="text"
                        fullWidth
                        value={values.license}
                        onChange={e =>
                          setFieldValue('license', e.target.value.toString())
                        }
                        error={touched.license && Boolean(errors.license)}
                        helperText={touched.license && errors.license}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        Address
                      </Typography>
                      <TextField
                        label="Address *"
                        variant="outlined"
                        name={'address'}
                        fullWidth
                        value={values.address}
                        onChange={e => setFieldValue('address', e.target.value)}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        State
                      </Typography>

                      <FormControl
                        fullWidth
                        error={touched.state && Boolean(errors.state)}
                        helperText={touched.state && errors.state}
                      >
                        <Select
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 9999 })
                          }}
                          placeholder="Select State *"
                          value={selectedState}
                          options={stateOptions}
                          fullWidth
                          onChange={(e) => {
                            setSelectedState(e)
                            setFieldValue("state", e.value)
                            setSelectedCity(null)
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        City
                      </Typography>
                      <FormControl
                        fullWidth
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && Boolean(errors.city)}
                      >
                        <Select
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 9999 })
                          }}
                          placeholder="Select City *"
                          value={selectedCity}
                          options={cityOptions}
                          fullWidth
                          isDisabled={!selectedState}
                          onChange={(e) => {
                            setSelectedCity(e)
                            setFieldValue("city", e.value)
                          }}
                        />

                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        Pincode
                      </Typography>
                      <TextField
                        label="Pincode *"
                        variant="outlined"
                        name={'pincode'}
                        fullWidth
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={values.pincode}
                        onChange={e => setFieldValue('pincode', e.target.value)}
                        error={touched.pincode && Boolean(errors.pincode)}
                        helperText={touched.pincode && errors.pincode}
                      />
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        flex-direction="column"
                        alignItems="center"
                        marginTop={2}
                      >
                        <Checkbox
                          checked={values.whatsappCommunicatioun}
                          onChange={e =>
                            setFieldValue(
                              'whatsappCommunicatioun',
                              e.target.checked
                            )
                          }
                        />
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'light',
                          }}
                        >
                          Receive important notifications via email and sms.
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                  <Grid item container xs={12}>
                    <Button
                      id="send_otp"
                      size={'large'}
                      variant={'contained'}
                      type={'submit'}
                      disabled={isSubmitting || showSuccessMessage}
                    >
                      {isSubmitting ? <CircularProgress /> : 'Confirm'}
                    </Button>
                  </Grid>
                  {showSuccessMessage &&
                    <Grid item container xs={12}>
                    <Typography
                          variant={'h6'}
                          sx={{ marginBottom: 1 , color : "blue"}}
                        >
                          We have received your details. We will contact you soon.
                          You can also call/whatsapp us at +91 7337279980.
                      </Typography>
                  </Grid>
                  }
                </Grid>
              </form >
            </>
          )
        }}
      </Formik>
    </Box >
  )
}

export default Form
