

const API_ROUTES = {
  // student routes
  getStudents: '/student',
  getStudentById: '/student/:id',
  createStudent: '/student',
  updateStudent: "/student/:id",
  deleteStudent: '/student/:id',

  // attendance routes
  getAttendanceByStudentId: '/attendance',
  updateAttendance: "/attendance/:id",
  deleteAttendance: "/attendance/:id",

  // drivers routes
  getDrivers: '/driver',
  getDriverById: '/driver/:id',
  createDriver: '/driver',
  updateDriver: '/driver/:id',
  deleteDriver: "/driver/:id",

  // videos routes
  getVideos: "/video",
  createVideo: "/video",
  deleteVideo: "/video/:videoCategoryId",

  // video categories routes
  getVideoCategories: "/video-category",
  createVideoCategory: "/video-category",
  deleteVideoCategory: "/video-category/:videoCategoryId",

  // organzation routes
  getOrganizations: '/organization',
  getOrganizationById: '/organization/:id',
  createOrganization: '/organization',
  updateOrganization: '/organization/:id',
  // deleteOrganization : "/delete-driver/:id",


  // expenses routes
  getExpenses: "/expense",

  // auth
  getRefreshToken: '/auth/refresh-token',

  // admin
  getAdmins: '/',
  createAdmin: '/',
  deleteAdmin: '/:id',

  // auth
  checkOwnerExistence: "/check-owner-existence",
  getOwnerDetails: "/owner-details",

  // contact Us
  sendMessage: "/contact-us"

};

export default API_ROUTES;
