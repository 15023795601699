import React, { useState } from "react";
import { storage } from './../../../config/firebase';
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { alpha, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "../../GeneralComponents/Container";
import { Topbar, Sidebar } from "../components";
import setGlobalLoader from "../../../redux/actions/loaderActions";
import { createVideoCategory } from "../../../redux/actions/apiActions";
import { Chip, CircularProgress, Stack } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const validationSchema = yup.object({
  title: yup.string().trim().required("Name is required."),
  // googleImageId: yup.string().trim().required("googleImageId is required."),
});


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ImagePreview = styled('img')({
  maxWidth: '100%',
  maxHeight: 200,
  objectFit: 'contain',
  borderRadius: 4,
  padding: 8,
  backgroundColor: '#fff',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
});

const AddVideoCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const role = useSelector((state) => state.authReducer.role);
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const isImageFile = (file) => file.type.startsWith('image/');

  console.log("image url : ", imageUrl)
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && isImageFile(file)) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };


  const handleImageUpload = (e) => {

    if (selectedImage && !isUploading) {
      setIsUploading(true);
      uploadImage(selectedImage);
    }
  }
  const uploadImage = async (image) => {
    try {
      const uploadTask = storage.ref(`${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          console.log("Upload error : ", error);
          setIsUploading(false);
        },
        () => {
          storage
            .ref()
            .child(selectedImage.name)
            .getDownloadURL()
            .then((url) => {
              console.log("image url : ", url);
              setImageUrl(image.name); 
              setIsUploading(false);
            });
        });
    } catch (error) {
      console.error("error while uploading", error);
      toast.error(
        error.response.data.msg ??
        "some error occured while uploading the image"
      );
      setIsUploading(false);
    }
  }

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const open = isMd ? false : openSidebar;

  const handleOnSubmit = async (values) => {
    if (!imageUrl) {
      toast.error("Please upload an image before submitting.");
      return;
    }
    let formData = new FormData();

    formData.append("googleImageId", imageUrl)
    console.log("imageData: ", formData.get("googleImageId"))
    try {
      await createVideoCategory(role, { ...values, googleImageId: imageUrl });
      toast.success("Video Category added successfully");
      navigate("/video-category");
      dispatch(setGlobalLoader(false));
    } catch (error) {
      dispatch(setGlobalLoader(false));
      console.log("create Video Category error", error);
      toast.error(
        error.response.data.msg ??
        "some error occured while creating Video Category"
      );
    }
  };

  return (
    <Box>
      <AppBar
        position={"fixed"}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
        elevation={0}
      >
        <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
          <Topbar onSidebarOpen={handleSidebarOpen} />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant={isMd ? "permanent" : "temporary"}
      />
      <main>
        <Box height={{ xs: 58, sm: 66, md: 71 }} />
        <br />
        <br />

        <Box overflow="hidden" marginLeft={{ md: "270px" }}>
          <Typography
            variant={"subtitle2"}
            sx={{ marginBottom: 2 }}
            fontWeight={700}
          >
            Add Video Category
          </Typography>

          <Formik
            initialValues={{
              title: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleOnSubmit(values)}
            enableReinitialize
          >
            {(formikProps) => {
              const {
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 4 }}
                    sx={{ padding: "10px" }}
                  >
                    <Grid item xs={12} sm={6} md={7}>
                      <Typography
                        variant={"subtitle2"}
                        sx={{ marginBottom: 2 }}
                        fontWeight={700}
                      >
                        Video Category Title
                      </Typography>
                      <TextField
                        label="Title *"
                        variant="outlined"
                        name={"title"}
                        fullWidth
                        value={values.title}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7}>
                      <Typography
                        variant={"subtitle2"}
                        sx={{ marginBottom: 2 }}
                        fontWeight={700}
                      >
                        Image
                      </Typography>
                      <Stack direction="column" spacing={1} alignItems="start">

                        <Button
                          component='label'
                          role={Image}
                          variant="contained"
                          tabIndex={-1}
                        >
                          Choose Image
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleImageChange}
                            multiple
                          />
                        </Button>
                        {selectedImage && <Chip label={selectedImage.name} color="primary" />}
                        {imagePreview && (
                          <ImagePreview src={imagePreview} alt="Selected Image Preview" />
                        )}

                      </Stack>
                      <Button
                        variant="contained"
                        onClick={handleImageUpload}
                        disabled={isUploading}
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        sx={{ marginTop: 1 }}
                      >
                        upload Image
                        {isUploading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          'Upload'
                        )}
                      </Button>
                      {uploadProgress > 0 && <Typography variant="body2">Upload is {uploadProgress}% done</Typography>}
                    </Grid>

                    <br />
                    <br />
                    <br />
                    <br />
                  </Grid>
                  <br />
                  <Button
                    component={"button"}
                    variant={"contained"}
                    fontWeight={700}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress /> : "Submit"}
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </main>
    </Box>
  );
};

export default AddVideoCategory;
