import React, { useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Container from "../../GeneralComponents/Container";
import { Topbar, Sidebar, Table } from "../components";
import {
    deleteVideo,
    getVideos,
} from "../../../redux/actions/apiActions";
import setGlobalLoader from "../../../redux/actions/loaderActions";
import exportToExcel from "../../../utils/exportToExcel";

const TableComponent = ({ columns, data, title }) => {
    const theme = useTheme();

    return (
        <Box p={4}>
            <Box
                width={1}
                height={1}
                // minHeight={800}
                borderRadius={2}
                border={`2px solid ${theme.palette.divider}`}
                sx={{
                    borderStyle: "dashed",
                }}
            >
                <Table
                    columns={columns}
                    data={data}
                    export={false}
                    // search
                    selection={false}
                    toolbar
                    title={title}
                />
            </Box>
        </Box>
    );
};

const Videos = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [videos, setVideos] = useState([]);
    const theme = useTheme();
    const role = useSelector((state) => state.authReducer.role);
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const open = isMd ? false : openSidebar;

    const handleOnDelete = async (rowData) => {
        let text = `Are you sure you want to delete ${rowData.title}`;
        if (window.confirm(text) === true) {
            try {
                await deleteVideo(role, rowData._id);
                videosFunc();
                toast.success("Video deleted successfully");
                navigate("/videos");
                dispatch(setGlobalLoader(false));
            } catch (error) {
                dispatch(setGlobalLoader(false));
                console.log("Delete Video error", error);
                toast.error(
                    error.response.data.msg ??
                    "some error occured while deleting Vedio"
                );
            }
        } else {
            toast.success("Request Cancelled");
        }
    };

    const headers = ["Sr no.", "Title", "Category", "Video"];
    const columns = [
        { title: "Sr no.", field: "index", filtering: false },
        { title: "Title", field: "title", filtering: false },
        { title: "Category", field: "videoCategoryName", filtering: false },
        { title: "Video", field: "videoId", filtering: false },
        {
            title: "Actions",
            field: "",
            filtering: false,
            render: (rowData) => (
                <div className="tableActions ">
                    <Tooltip title="Delete">
                        <DeleteIcon onClick={() => handleOnDelete(rowData)} />
                    </Tooltip>
                </div>
            ),
        },
    ];

    async function videosFunc() {
        dispatch(setGlobalLoader(true));
        try {
            const vidRes = await getVideos(role);
            const data = vidRes.data.data.map((d, i) => ({
                ...d,
                index: i + 1,
                videoCategoryName : d?.videoCategoryId?.title ?? "",
            }));
            setVideos(data);
            dispatch(setGlobalLoader(false));
        } catch (error) {
            dispatch(setGlobalLoader(false));
            console.log("get Videos error", error);
            toast.error(error.response.data.msg);
        }
    }

    useEffect(() => {
        videosFunc();
    }, []);

    return (
        <Box>
            <AppBar
                position={"fixed"}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                }}
                elevation={0}
            >
                <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
                    <Topbar onSidebarOpen={handleSidebarOpen} />
                </Container>
            </AppBar>
            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant={isMd ? "permanent" : "temporary"}
            />
            <main>
                <Box height={{ xs: 58, sm: 66, md: 71 }} />
                <Box
                    display="flex"
                    flex="1 1 auto"
                    overflow="hidden"
                    paddingLeft={{ md: "256px" }}
                >
                    <Box display="flex" flex="1 1 auto" overflow="hidden">
                        <Box flex="1 1 auto" height="100%" overflow="auto">
                            <Box
                                marginTop={3}
                                mx={{ xs: 3, md: 4 }}
                                display={"flex"}
                                justifyContent={"space-between"}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="button"
                                    size="medium"
                                    onClick={() => {
                                        navigate("/video/add");
                                    }}
                                >
                                    Add Video
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component="button"
                                    size="medium"
                                    onClick={async () => {
                                        const rows = videos.map((row) => ({
                                            "Sr No. ": row.index,
                                            name: row.title,
                                            image: row.googleImageId,
                                        }));
                                        await exportToExcel(rows, headers, "Videos");
                                    }}
                                >
                                    Download
                                </Button>
                            </Box>
                            <TableComponent
                                data={videos}
                                columns={columns}
                                title="Videos"
                            />
                            <Divider />
                            {/* <Container paddingY={4}>
                <Footer />
              </Container> */}
                        </Box>
                    </Box>
                </Box>
            </main>
        </Box>
    );
};

export default Videos;
