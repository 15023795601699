
export const states = []
export const statesAndCities = [
        {
                state: "Andhra Pradesh",
                city: [
                        "Visakhapatnam",
                        "Vijayawada",
                        "Tirupati",
                        "Kurnool",
                        "Rajahmundry",
                        "Kakinada",
                        "Nellore",
                        "Guntur",
                        "Anantapur",
                        "Kadapa"
                ]
        },
        {
                state: "Arunachal Pradesh",
                city: [
                        "Itanagar",
                        "Tawang",
                        "Bomdila",
                        "Dirang",
                        "Ziro",
                        "Pasighat",
                        "Aalo",
                        "Seppa"
                ]
        },
        {
                state: "Assam",
                city: [
                        "Guwahati",
                        "Silchar",
                        "Dibrugarh",
                        "Jorhat",
                        "Nagaon",
                        "Tinsukia",
                        "Tezpur",
                        "Bongaigaon",
                        "Sivasagar"
                ]
        },
        {
                state: "Bihar",
                city: [
                        "Patna",
                        "Gaya",
                        "Bhagalpur",
                        "Muzaffarpur",
                        "Purnia",
                        "Darbhanga",
                        "Begusarai",
                        "Hajipur",
                        "Sasaram"
                ]
        },
        {
                state: "Chhattisgarh",
                city: [
                        "Raipur",
                        "Bilaspur",
                        "Durg",
                        "Rajnandgaon",
                        "Korba",
                        "Ambikapur",
                        "Jagdalpur",
                        "Raigarh",
                        "Surguja"
                ]
        },
        {
                state: "Delhi",
                city: [
                        "Delhi",
                ]
        },
        {
                state: "Goa",
                city: [
                        "Panaji",
                        "Vasco da Gama",
                        "Margao",
                        "Mapusa",
                        "Ponda",
                        "Canacona",
                        "Quepem",
                        "Sanguem",
                        "Valpoi"
                ]
        },
        {
                state: "Gujarat",
                city: [
                        "Gandhinagar",
                        "Ahmedabad",
                        "Vadodara",
                        "Surat",
                        "Rajkot",
                        "Bhavnagar",
                        "Jamnagar",
                        "Morbi",
                        "Gandhidham"
                ]
        },
        {
                state: "Haryana",
                city: [
                        "Chandigarh",
                        "Faridabad",
                        "Gurgaon",
                        "Rohtak",
                        "Hisar",
                        "Panipat",
                        "Karnal",
                        "Ambala",
                        "Sonipat"
                ]
        },
        {
                state: "Himachal Pradesh",
                city: [
                        "Shimla",
                        "Dharamshala",
                        "Solan",
                        "Mandi",
                        "Kangra",
                        "Chamba",
                        "Dalhousie",
                        "Manali",
                        "Kullu"
                ]
        },
        {
                state: "Jharkhand",
                city: [
                        "Ranchi",
                        "Jamshedpur",
                        "Dhanbad",
                        "Bokaro",
                        "Deoghar",
                        "Hazaribagh",
                        "Giridih",
                        "Ramgarh",
                        "Medininagar"
                ]
        },
        {
                state: "Karnataka",
                city: [
                        "Bengaluru",
                        "Mysuru",
                        "Mangaluru",
                        "Hubballi",
                        "Dharwad",
                        "Belagavi",
                        "Gulbarga",
                        "Davanagere",
                        "Tumakuru"
                ]
        },
        {
                state: "Kerala",
                city: [
                        "Thiruvananthapuram",
                        "Kochi",
                        "Kozhikode",
                        "Kollam",
                        "Thrissur",
                        "Palakkad",
                        "Malappuram",
                        "Idukki",
                        "Wayanad"
                ]
        },
        {
                state: "Madhya Pradesh",
                city: [
                        "Bhopal",
                        "Indore",
                        "Ujjain",
                        "Gwalior",
                        "Jabalpur",
                        "Sagar",
                        "Satna",
                        "Ratlam",
                        "Rewa"
                ]
        },
        {
                state: "Maharashtra",
                city: [
                        "Mumbai",
                        "Pune",
                        "Nagpur",
                        "Thane",
                        "Aurangabad",
                        "Nashik",
                        "Solapur",
                        "Amravati",
                        "Kolhapur"
                ]
        },
        {
                state: "Manipur",
                city: [
                        "Imphal",
                        "Thoubal",
                        "Churachandpur",
                        "Bishnupur",
                        "Ukhrul",
                        "Senapati",
                        "Tamenglong",
                        "Chandel",
                        "Kakching"
                ]
        },
        {
                state: "Meghalaya",
                city: [
                        "Shillong",
                        "Tura",
                        "Jowai",
                        "Nongpoh",
                        "Williamnagar",
                        "Baghmara",
                        "Nongstoin",
                        "Khliehriat",
                        "Mawkyrwat"
                ]
        },
        {
                state: "Mizoram",
                city: [
                        "Aizawl",
                        "Lunglei",
                        "Champhai",
                        "Mamit",
                        "Kolasib",
                        "Serchhip",
                        "Lawngtlai",
                        "Saiha",
                        "Khawzawl"
                ]
        },
        {
                state: "Nagaland",
                city: [
                        "Kohima",
                        "Dimapur",
                        "Mokokchung",
                        "Zunheboto",
                        "Tuensang",
                        "Mon",
                        "Wokha",
                        "Phek",
                        "Longleng"
                ]
        },
        {
                state: "Odisha",
                city: [
                        "Bhubaneswar",
                        "Cuttack",
                        "Rourkela",
                        "Berhampur",
                        "Sambalpur",
                        "Puri",
                        "Bolangir",
                        "Baripada",
                        "Koraput"
                ]
        },
        {
                state: "Punjab",
                city: [
                        "Chandigarh",
                        "Ludhiana",
                        "Amritsar",
                        "Jalandhar",
                        "Patiala",
                        "Bathinda",
                        "Pathankot",
                        "Hoshiarpur",
                        "Moga"
                ]
        },
        {
                state: "Rajasthan",
                city: [
                        "Jaipur",
                        "Jodhpur",
                        "Kota",
                        "Bikaner",
                        "Ajmer",
                        "Udaipur",
                        "Bhilwara",
                        "Alwar",
                        "Sikar"
                ]
        },
        {
                state: "Sikkim",
                city: [
                        "Gangtok",
                        "Namchi",
                        "Pelling",
                        "Ravangla",
                        "Jorethang",
                        "Mangan",
                        "Chungthang",
                        "Soreng",
                        "Yuksom"
                ]
        },
        {
                state: "Tamil Nadu",
                city: [
                        "Chennai",
                        "Coimbatore",
                        "Madurai",
                        "Tiruchirappalli",
                        "Salem",
                        "Tirunelveli",
                        "Tiruppur",
                        "Erode",
                        "Vellore"
                ]
        },
        {
                state: "Telangana",
                city: [
                        "Hyderabad",
                        "Warangal",
                        "Karimnagar",
                        "Nizamabad",
                        "Khammam",
                        "Siddipet",
                        "Jagtial",
                        "Adilabad",
                        "Nalgonda"
                ]
        },
        {
                state: "Tripura",
                city: [
                        "Agartala",
                        "Dharmanagar",
                        "Kailashahar",
                        "Belonia",
                        "Udaipur",
                        "Sabroom",
                        "Sonamura",
                        "Khowai",
                        "Bishalgarh"
                ]
        },
        {
                state: "Uttarakhand",
                city: [
                        "Dehradun",
                        "Haridwar",
                        "Haldwani",
                        "Rudrapur",
                        "Rishikesh",
                        "Kashipur",
                        "Uttarkashi",
                        "Pithoragarh",
                        "Chamoli"
                ]
        },
        {
                state: "Uttar Pradesh",
                city: [
                        "Lucknow",
                        "Kanpur",
                        "Ghaziabad",
                        "Agra",
                        "Varanasi",
                        "Allahabad",
                        "Bareilly",
                        "Aligarh",
                        "Moradabad"
                ]
        },
        {
                state: "West Bengal",
                city: [
                        "Kolkata",
                        "Asansol",
                        "Siliguri",
                        "Durgapur",
                        "Bardhaman",
                        "Malda",
                        "Baharampur",
                        "Jalpaiguri",
                        "Haldia"
                ]
        }]

export const STUDENT_STATUS = {
        ONBOARDED: "ONBOARDED",
        INPROGRESS: "INPROGRESS",
        COMPLETED: "COMPLETED",
}

export const PROFILES = {
        ADMIN: "ADMIN",
        ORGANIZATION: "ORGANIZATION",
}